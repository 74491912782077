.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.app-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #487eb0 !important;
}

.App-link {
  color: #09d3ac;
}
