@import url(https://fonts.googleapis.com/css?family=Barlow:400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.app-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #487eb0 !important;
}

.App-link {
  color: #09d3ac;
}

body, h1, h2, h3, h4, h5, h6, span, div, p, button, th, td {
  font-family: "Barlow"; }

a {
  text-decoration: none; }

.button-base {
  color: #ffffff;
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  cursor: pointer;
  outline: 0; }
  .button-base.button-primary {
    background: #487eb0;
    border: solid 1px #487eb0; }
  .button-base.button-primary:hover {
    background: #41719e; }
  .button-base.button-primary:active {
    background: #3a658d; }
  .button-base.button-secondary {
    background: #f5f6fa;
    border: solid 1px #f5f6fa;
    color: #000000; }
  .button-base.button-secondary:hover {
    background: #d4d8ea; }
  .button-base.button-secondary:active {
    background: #b3bbd9; }
  .button-base.button-tertiary {
    background: rgba(0, 0, 0, 0);
    border: solid 1px rgba(0, 0, 0, 0);
    color: #333333; }
  .button-base.button-tertiary:hover {
    background: rgba(0, 0, 0, 0.1); }
  .button-base.button-tertiary:active {
    background: rgba(0, 0, 0, 0.2); }
  .button-base.button-danger {
    background: #c2004b;
    border: solid 1px #c2004b; }
  .button-base.button-danger:hover {
    background: #af0044; }
  .button-base.button-danger:active {
    background: #9b003c; }

.button-outline {
  border: solid 1px;
  background: rgba(0, 0, 0, 0);
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  cursor: pointer;
  outline: 0; }
  .button-outline:hover {
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
  .button-outline.button-primary {
    background: rgba(0, 0, 0, 0);
    border: solid 1px #487eb0;
    color: #487eb0; }
  .button-outline.button-primary:hover {
    color: #ffffff; }
  .button-outline.button-primary:active {
    border: solid 1px #487eb0;
    background: #487eb0;
    color: #ffffff; }
  .button-outline.button-secondary {
    background: rgba(0, 0, 0, 0);
    border: solid 1px #f5f6fa; }
  .button-outline.button-secondary:active {
    border: solid 1px #f5f6fa;
    background: #f5f6fa; }
  .button-outline.button-tertiary:active {
    background: rgba(0, 0, 0, 0);
    border: #333333;
    background: #333333;
    color: #ffffff; }
  .button-outline.button-danger {
    background: rgba(0, 0, 0, 0);
    border: solid 1px #c2004b;
    color: #c2004b; }
  .button-outline.button-danger:hover {
    color: #ffffff; }
  .button-outline.button-danger:active {
    border: solid 1px #c2004b;
    background: #c2004b;
    color: #ffffff; }

.button-large {
  font-size: 18px;
  padding: 10px 14px; }

.button-small {
  font-size: 12px;
  padding: 3px 8px; }

.button-block {
  width: 100%; }

.form-input {
  border: solid 1px #dcdde1;
  border-radius: 5px;
  padding: 8px 5px; }

.form-select-input {
  border: solid 1px #dcdde1;
  border-radius: 5px;
  padding: 8px 5px;
  box-sizing: border-box;
  margin: 0;
  background-color: #fff;
  line-height: 5px;
  height: 31px; }

.form-textarea {
  border: solid 1px #dcdde1;
  border-radius: 5px;
  padding: 8px 5px; }

.input-block {
  width: 100%;
  box-sizing: border-box; }

.dropdown-toggle {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 5px;
  box-sizing: border-box;
  color: #487eb0; }
  .dropdown-content .nav {
    color: #487eb0; }

.dropdown-content-right {
  right: 0; }

.dropdown-content-left {
  left: 0; }

.dropdown-toggle:hover .dropdown-content {
  display: block; }

@media screen and (max-width: 575.99px) {
  .form-input {
    width: 100%; }
  .form-select-input {
    width: 100%; } }

.form-checkbox-container .form-checkbox-input:checked,
.form-checkbox-container .form-checkbox-input:not(:checked) {
  position: absolute;
  left: -9999px; }

.form-checkbox-container .form-checkbox-input:checked + label,
.form-checkbox-container .form-checkbox-input:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block; }

.form-checkbox-container .form-checkbox-input:checked + label:before,
.form-checkbox-container .form-checkbox-input:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #c8d6e5;
  background: #ffffff; }

.form-checkbox-container .form-checkbox-input:checked + label:after,
.form-checkbox-container .form-checkbox-input:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #487eb0;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.form-checkbox-container .form-checkbox-input:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.form-checkbox-container .form-checkbox-input:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.form-checkbox-container .form-checkbox-input:disabled:checked + label:before,
.form-checkbox-container .form-checkbox-input:disabled:not(:checked) + label:before {
  border-color: #c8d6e5;
  background-color: #f5f6fa; }

.form-checkbox-container .form-checkbox-input:disabled:checked + label:after {
  background: #c8d6e5; }

.form-radio-container .form-radio-input:checked,
.form-radio-container .form-radio-input:not(:checked) {
  position: absolute;
  left: -9999px; }

.form-radio-container .form-radio-input:checked + label,
.form-radio-container .form-radio-input:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block; }

.form-radio-container .form-radio-input:checked + label:before,
.form-radio-container .form-radio-input:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #c8d6e5;
  background: #ffffff;
  border-radius: 9px; }

.form-radio-container .form-radio-input:checked + label:after,
.form-radio-container .form-radio-input:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #487eb0;
  border-radius: 4px;
  position: absolute;
  top: 6px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.form-radio-container .form-radio-input:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.form-radio-container .form-radio-input:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.form-radio-container .form-radio-input:disabled:checked + label:before,
.form-radio-container .form-radio-input:disabled:not(:checked) + label:before {
  border-color: #c8d6e5;
  background-color: #f5f6fa; }

.form-radio-container .form-radio-input:disabled:checked + label:after {
  background: #c8d6e5; }

div {
  /* Safari/Chrome, other WebKit */
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */ }

.content {
  width: 100%;
  padding: 0;
  /* Safari/Chrome, other WebKit */
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */ }

.container {
  width: 100%;
  padding: 10px;
  /* Safari/Chrome, other WebKit */
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */ }

.container-flex {
  display: flex; }

* [condensed] {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0; }

.separator {
  width: 100%;
  display: block; }

.card {
  border-radius: 5px;
  border: solid 1px #dcdde1;
  display: inline-block;
  margin: 5px; }

.card-header {
  font-size: 1.5em;
  padding: 1.5em 0.5em;
  background-color: #f5f6fa; }

.card-body {
  padding: 1.5em 0.5em; }

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.enum-row {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .enum-row .enum-item {
    margin-right: 5px;
    margin-bottom: 5px; }

.enum-column {
  margin: 0 5px;
  display: flex;
  flex-direction: column; }
  .enum-column .enum-item {
    margin-right: 5px;
    margin-bottom: 5px; }

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box; }

.col {
  flex-grow: 1;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px; }

.col:first-child {
  padding-left: 0; }

.col:last-child {
  padding-right: 0;
  padding: 0; }

.col-inline {
  display: flex;
  flex-direction: row !important; }

.col {
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

.col-left {
  justify-content: flex-start; }

.col-right {
  justify-content: flex-end; }

.col-v-center {
  align-items: center; }

.col-h-center {
  justify-content: center; }

.col-menu {
  background-color: #f5f6fa; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0; }

.header {
  height: 80px;
  width: 100%;
  padding: 0;
  background-color: #487eb0;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .header .row {
    padding: 0; }

.footer {
  height: 50px;
  width: 100%;
  padding: 0;
  background-color: #353b48;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
  font-size: 13px; }

.show-lt-sm,
.show-lt-md,
.show-lt-lg,
.show-lt-xl,
.show-gt-sm,
.show-gt-md,
.show-gt-lg,
.show-gt-xl {
  display: none; }

@media screen and (max-width: 1199.99px) {
  .hide-lt-xl {
    display: none; }
  .show-lt-xl {
    display: flex; }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; } }

@media screen and (max-width: 991.99px) {
  .hide-lt-lg {
    display: none; }
  .show-lt-lg {
    display: flex; }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; } }

@media screen and (max-width: 767.99px) {
  .hide-lt-md {
    display: none; }
  .show-lt-md {
    display: flex; }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; } }

@media screen and (max-width: 575.99px) {
  .hide-lt-sm {
    display: none; }
  .show-lt-sm {
    display: flex; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; } }

@media screen and (min-width: 575.99px) {
  .show-gt-sm {
    display: flex; }
  .hide-gt-sm {
    display: none; } }

@media screen and (min-width: 767.99px) {
  .show-gt-md {
    display: flex; }
  .hide-gt-md {
    display: none; } }

@media screen and (min-width: 991.99px) {
  .show-gt-lg {
    display: flex; }
  .hide-gt-lg {
    display: none; } }

@media screen and (min-width: 1199.99px) {
  .show-gt-xl {
    display: flex; }
  .hide-gt-xl {
    display: none; } }

* [dark] {
  background-color: #353b48;
  color: #f5f6fa; }

.eva {
  -webkit-transform: none !important;
          transform: none !important; }

.col-dark,
.row-dark,
.container-dark,
.content-dark,
.modal-dark {
  background-color: #353b48;
  color: #f5f6fa; }

.col-pull-right,
.row-pull-right,
.enum-pull-right,
.button-pull-right,
.container-pull-right,
.content-pull-right {
  text-align: right; }

.col-pull-left,
.row-pull-left,
.enum-pull-left,
.button-pull-left,
.container-pull-left,
.content-pull-left {
  text-align: left; }

.col-pull-h-center,
.row-pull-h-center,
.enum-pull-v-center,
.container-pull-h-center,
.content-pull-h-center {
  text-align: center;
  align-items: center; }

.col-pull-v-center,
.row-pull-v-center,
.enum-pull-h-center,
.container-pull-v-center,
.content-pull-v-center {
  justify-content: center; }

.modal-backdrop {
  z-index: 1020;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3); }

.modal {
  z-index: 1030;
  position: fixed;
  top: 3vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 5px;
  background-color: #ffffff;
  min-width: 400px;
  max-height: 95vh;
  overflow-y: auto;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  overflow-x: auto;
  max-width: 800px; }

.modal-inline {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transform: unset;
          transform: unset;
  z-index: 1000; }

.modal-large {
  min-width: 800px; }

.modal-xlarge {
  min-width: 1000px; }

.modal-close-button {
  float: right;
  margin: 0.3em 0.4em;
  cursor: pointer;
  font-size: 16px;
  color: #718093;
  border-radius: 3px;
  background: #ffffff;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 16px; }

.modal-close-button:hover {
  -webkit-filter: brightness(95%);
          filter: brightness(95%); }

.modal-close-button:focus {
  -webkit-filter: brightness(85%);
          filter: brightness(85%); }

.modal-header {
  width: 100%;
  padding: 1em 1em 0 1em;
  font-size: 1.5em;
  text-align: left; }

.modal-content {
  width: 100%;
  padding: 1.5em;
  font-size: 0.9em; }

.modal-footer {
  width: 100%;
  padding: 1em;
  background-color: #f5f6fa;
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }
  .modal-footer * {
    margin-left: 5px; }

@media screen and (max-width: 575.99px) {
  .modal {
    left: 1vw;
    width: 98vw;
    -webkit-transform: none;
            transform: none;
    top: 1vh;
    min-width: unset; } }

.spinner {
  display: inline-block;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #000000;
  border-right-color: #000000;
  border-bottom-color: #000000;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

.table-header-row {
  background: #487eb0;
  color: #ffffff;
  font-weight: 700; }

.table-header-cell, .table-cell {
  padding: 5px 10px;
  text-align: left; }

.table-header-cell-condensed, .table-cell-condensed {
  padding: 5px 10px;
  text-align: left; }

.table-row:nth-child(even) {
  background-color: #f5f6fa; }

.table-row:nth-child(odd) {
  background-color: #dfe4ea; }

.tabs-container {
  border-bottom: solid 1px #c8d6e5;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
  box-sizing: border-box; }

.tab {
  cursor: pointer;
  color: #718093;
  padding: 0.5rem 1rem; }

.tab:hover {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #f5f6fa; }

.tab-active {
  color: #487eb0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top: solid 1px #c8d6e5;
  border-right: solid 1px #c8d6e5;
  border-left: solid 1px #c8d6e5;
  background: #ffffff;
  border-bottom: solid 1px #ffffff;
  box-sizing: border-box;
  margin-bottom: -1px; }

.tab-active:hover {
  background: #ffffff; }

.tab-disabled {
  cursor: not-allowed;
  color: #c8d6e5;
  pointer-events: none; }

.pills-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
  box-sizing: border-box; }

.pills-container-column {
  flex-direction: column; }

.pill {
  cursor: pointer;
  color: #487eb0;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 0.5rem; }

.pill-active {
  background-color: #487eb0;
  color: #ffffff; }

.pill-disabled {
  cursor: not-allowed;
  color: #c8d6e5;
  pointer-events: none; }

.nav-container {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
  box-sizing: border-box;
  color: #487eb0; }
  .nav-container .nav-separator-container {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .nav-container .nav-separator {
    width: 1px;
    height: 60%;
    border-bottom: 0;
    border-left: solid 1px #dcdde1; }

.nav-container-column {
  flex-direction: column;
  box-sizing: border-box; }
  .nav-container-column .nav-separator {
    width: 100%;
    height: 1px;
    border-bottom: solid 1px #dcdde1;
    border-left: 0; }
  .nav-container-column .nav {
    cursor: pointer;
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    text-decoration: none;
    color: #353b48; }

.nav {
  cursor: pointer;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex; }

.nav:hover {
  background: rgba(0, 0, 0, 0.1); }

.nav-active {
  font-weight: bold; }

.nav-disabled {
  cursor: not-allowed;
  color: #c8d6e5;
  pointer-events: none; }

.block {
  width: 100%; }

* [block] {
  width: 100%; }

/* badges */
.badge {
  display: inline;
  font-size: 12px;
  line-height: 10px;
  color: #ffffff;
  border-radius: 3px;
  padding: 4px; }

.badge-large {
  font-size: 14px;
  padding: 7px; }

.badge-primary {
  background: #487eb0; }

.badge-secondary {
  background: #dcdde1;
  color: #353b48; }

.badge-success {
  background: #1dd1a1; }

.badge-warning {
  background: #ff9f43; }

.badge-danger {
  background: #c2004b; }

.badge-info {
  background: #2e86de; }

/* breadcrumbs */
.breadcrumbs-container {
  display: flex;
  flex-direction: row; }

.breadcrumbs-divider {
  display: inline;
  padding: 0 8px;
  color: #dcdde1; }

/* progress */
.progress-container {
  border: solid 1px #dcdde1;
  border-radius: 5px;
  height: 16px;
  overflow: hidden; }

.progress {
  height: 16px;
  border: 0; }

.progress-primary {
  background: #487eb0; }

.progress-secondary {
  background: #dcdde1;
  color: #353b48; }

.progress-success {
  background: #1dd1a1; }

.progress-warning {
  background: #ff9f43; }

.progress-danger {
  background: #c2004b; }

.progress-info {
  background: #2e86de; }

/* avatar */
.avatar {
  display: inline-block;
  border-radius: 900px;
  overflow: hidden;
  border: solid 1px #718093; }

/* avatar */
.initials {
  border-radius: 900px;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold; }

/* toast */
.toast-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: 1040;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.toast {
  width: 200px;
  min-height: 70px;
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@media screen and (max-width: 575.99px) {
  .toast {
    width: calc(100vw - 10px); } }

.toast:hover {
  -webkit-filter: brightness(95%);
          filter: brightness(95%);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75); }

.toast-success {
  background: #1dd1a1; }

.toast-warning {
  background: #ff9f43; }

.toast-danger {
  background: #c2004b; }

.toast-info {
  background: #2e86de; }

/* color */
.color-circle {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  margin: 10px;
  display: inline-block;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75); }

.color-circle:hover {
  border: solid 1px #dddddd; }

/* tooltip */
.tooltip {
  position: relative;
  display: inline-block; }
  .tooltip .tooltip-text {
    display: none;
    visibility: hidden;
    width: 120px;
    max-width: 250px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 1; }
  .tooltip .tooltip-text-right {
    left: calc(100% + 5px); }
  .tooltip .tooltip-text-right::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateX(-100%) translateY(-50%) translateY(1px);
            transform: translateX(-100%) translateY(-50%) translateY(1px);
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent; }
  .tooltip .tooltip-text-left {
    right: calc(100% + 5px); }
  .tooltip .tooltip-text-left::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translateY(-50%) translateY(1px);
            transform: translateY(-50%) translateY(1px);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black; }
  .tooltip .tooltip-text-top {
    bottom: calc(100% + 5px);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .tooltip .tooltip-text-top::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent; }
  .tooltip .tooltip-text-bottom {
    top: calc(100% + 5px);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .tooltip .tooltip-text-bottom::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent; }

.tooltip:hover .tooltip-text {
  visibility: visible;
  display: inline-block; }

/* alert */
.alert {
  border-radius: 3px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  margin: 8px 5px;
  box-sizing: border-box; }
  .alert .alert-content {
    flex: 1 1;
    flex-grow: 1;
    line-height: 24px;
    font-size: 14px; }
  .alert .alert-close {
    cursor: pointer;
    border-radius: 3px;
    padding: 4px 10px;
    background: rgba(0, 0, 0, 0.1);
    line-height: 20px;
    font-size: 11px; }
  .alert .alert-close:hover {
    background: rgba(0, 0, 0, 0.2); }

.alert-info {
  background: #2e86de; }

.alert-primary {
  background: #487eb0; }

.alert-secondary {
  background: #f5f6fa;
  color: #000000; }

.alert-warning {
  background: #ff9f43; }

.alert-success {
  background: #1dd1a1; }

.alert-danger {
  background: #c2004b; }

/* helper classes */
.mobile-menu {
  position: fixed;
  top: 80px;
  width: 100%;
  background: #ffffff;
  z-index: 100;
  border-bottom: solid 1px #cccccc;
  box-shadow: 0 0 3px #333333; }

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; }

.hint {
  margin: 5px 0;
  color: #353b48; }

.code {
  font-family: 'Courier New', Courier, monospace;
  margin: 5px 0;
  color: #353b48; }

.heading-condensed, .text-condensed {
  margin-top: 0;
  margin-bottom: 0; }

.demo-col {
  border: dashed 1px #cccccc; }

.logo {
  height: 25px;
  margin-left: 10px; }

.app-logo-text {
  color: #ffffff;
  font-size: 26px;
  font-weight: bold;
  padding-left: 15px; }

.ccd-link {
  color: #ffffff;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  -webkit-text-decoration-line: dashed;
          text-decoration-line: dashed; }

.demo-col {
  border: dashed 1px #cccccc; }

.nav a {
  color: #000000 !important;
  text-decoration: none !important; }

.logo {
  height: 25px;
  margin-left: 10px; }

code, code span {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New", monospace !important;
  font-size: 13px; }

.ds-menu-container {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  height: calc(100vh - 80px);
  overflow-y: auto; }

.color-title {
  padding: 20px 0 10px 0; }

.palette-column {
  overflow-y: auto;
  height: calc(100vh - 167px);
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555; }

.palette-container {
  height: calc(100vh - 187px); }

.palette-bg {
  background-color: #343a48; }
  .palette-bg h2 {
    color: #ffffff; }

.flexbox-fix span {
  color: #ffffff !important; }

.flexbox-fix svg {
  fill: #ffffff !important; }

.flexbox-fix svg:hover {
  background: #333333 !important; }

@media screen and (max-width: 768px) {
  .palette-container {
    height: unset; }
  .palette-column {
    overflow-y: unset;
    height: unset; } }

.chrome-picker {
  height: calc(100vh - 188px);
  box-shadow: 0 0 !important;
  background: rgba(0, 0, 0, 0) !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100% !important; }
  .chrome-picker > :first-child {
    border-radius: 0 !important;
    height: calc(100vh - 285px); }

.space-between {
  display: flex;
  justify-content: space-between;
  flex-direction: row; }

.demo-col {
  border: dashed 1px #cccccc; }

.logo {
  height: 25px;
  margin-left: 10px; }

.extra-row {
  background: #526de5;
  height: 100px;
  -webkit-transform: skewY(-6deg);
          transform: skewY(-6deg);
  -webkit-transform-origin: top left;
          transform-origin: top left; }

.jumbo {
  width: 100vw;
  height: calc(100vh - 130px);
  color: #273b75;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#487EB0), color-stop(99%, #e3eeff), to(#e3eeff));
  background-image: linear-gradient(to bottom, #487EB0 0%, #e3eeff 99%, #e3eeff 100%); }

.jumbo-content {
  max-width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.intro-content {
  min-height: 0;
  height: 300px; }

.ccd-logo {
  max-height: 10vh; }

.ccd-name {
  font-weight: 700;
  line-height: 1.5em;
  font-size: 2em;
  text-align: center; }

.ccdd-name {
  font-weight: 200;
  font-size: 2em; }

.ccdd-about {
  font-size: 1em;
  line-height: 1.2em;
  max-width: 70%;
  text-align: center; }

@media screen and (max-width: 576px) {
  .jumbo {
    min-height: calc(100vh - 130px);
    height: unset; }
  .jumbo-content {
    max-width: 90vw; }
  .ccdd-name {
    display: none; }
  .ccdd-about {
    margin-top: 0.8em;
    font-size: 1em;
    line-height: 1.1em;
    max-width: 100%; } }


.about-container {
  min-height: calc(100vh - 130px); }

.about-container {
  min-height: calc(100vh - 130px); }

.back-button {
  position: absolute;
  left: 2px;
  top: 2px; }

.layout-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
  z-index: 1111; }

.login-container {
  width: 100%;
  height: 100%; }

.layout-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1111; }
  .layout-container .dashboard-app-row {
    height: calc(100vh - 130px);
    background: #ffffff;
    overflow-y: auto; }
  .layout-container .dashboard-menu {
    background-color: #ecf0f1; }
  .layout-container .nav-icon {
    margin-right: 5px;
    font-size: 20px !important; }
  .layout-container li.nav {
    margin: 4px 0; }

.about-container {
  min-height: calc(100vh - 130px); }

