@import './tokens.scss';

.spinner {
    display: inline-block;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: map-get($colors, 'black');
    border-right-color: map-get($colors, 'black');
    border-bottom-color: map-get($colors, 'black');
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }